import { FILTERS_EQUAL } from "@kraftheinz/common";
import { n as normalizeComponent, a as StuckClaimsUtils } from "./index.js";
import "vue";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { staticClass: "modal-edit-threshold", attrs: { "title": "Edit Key Account", "width": 600 } }, [_c("select-input", { key: "region", attrs: { "label": "Region", "reference": "stuck-claims.common.region", "source": "code", "source-label": "description", "on-select-change": _vm.onRegionChange, "clear-data-on-destroy": false, "placeholder": "Select region", "span": 12, "rules": "required" } }), _c("select-input", { key: "keyAccount", attrs: { "label": "Key Account", "advanced-filters": _vm.queriesByRegion, "queries": _vm.defaultQuery, "reference": "stuck-claims.common.key-account", "source": "keyAccount", "source-label": function(opt) {
    return _vm.generateLabel(opt, ["keyAccount", "keyAccountDesc"]);
  }, "source-description": function(opt) {
    return _vm.generateLabel(opt, ["keyAccount", "keyAccountDesc"]);
  }, "placeholder": "Key Account", "rules": "required", "span": 12 } }), _c("text-input", { key: "variance", attrs: { "label": "Variance Threshold", "min": 0, "placeholder": "Variance Threshold", "rules": "number|positive|decimal:10,4|required", "prefix": "$", "max-length": 14, "span": 12 } }), _c("text-input", { key: "impact", attrs: { "label": "Impact Threshold", "min": 0, "placeholder": "Impact Threshold ", "rules": "number|positive|decimal:10,4|required", "prefix": "$", "max-length": 14, "span": 12 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditThreshold",
  mixins: [StuckClaimsUtils],
  inject: ["crud"],
  data() {
    return {
      selectedRegion: null,
      isLoadingCustomer: false
    };
  },
  computed: {
    thresholdsEntity() {
      return this.crud.getEntity(this.$route.params.id);
    },
    queriesByRegion() {
      if (!this.selectedRegion)
        return null;
      return [
        ["Region", { operator: FILTERS_EQUAL, value: this.selectedRegion }]
      ];
    },
    defaultQuery() {
      return [["isThreshold", true]];
    }
  },
  watch: {
    thresholdsEntity(newVal) {
      this.selectedRegion = newVal.region;
    }
  },
  methods: {
    onRegionChange(value) {
      this.selectedRegion = value;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditThresholds = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-thresholds" }, [_c("resource", { attrs: { "name": "stuck-claims.common.region", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "stuck-claims.common.key-account", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "stuck-claims.thresholds", "api-url": _vm.apiUrl, "redirect-route": "/stuck-claims/thresholds-management" } }, [_c("edit-thresholds")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditThresholds
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
